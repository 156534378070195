import React, { useState } from "react";

import { Button, Col, Input, Modal, Row, Space, Table, Tooltip } from "antd";
import { URL } from "../../../Redux/common/url";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import { Image } from "react-bootstrap";
import SvgIcon from "../../const/SvgIcon";
import { useDispatch } from "react-redux";
import { allapiAction } from "../../../Redux/common/action";
function Frorders() {
  const [page, setpage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userselete, setuserselete] = useState(null);
  const [statusData, setstatusData] = useState({});
  const [ListProduct, setListProduct] = useState([]);

  console.log(userselete);

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };
  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/productGetforsalingbysalesman?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label =
        data?.productId?.title +
        "-" +
        data?.productcolorId?.color +
        "-" +
        data?.size;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    if (!statusData.item) {
      toast?.error("please enter quantity");
      return;
    }
    console.log(userselete?.stock);
    console.log(statusData.item);
    console.log(userselete);

    const existingItemIndex = ListProduct.findIndex(
      (item) => item._id === userselete._id
    );

    if (existingItemIndex !== -1) {
      const existingItem = ListProduct[existingItemIndex];
      const totalItemCount =
        parseInt(existingItem.item) + parseInt(statusData.item);

      if (userselete?.stock < totalItemCount) {
        toast?.error(
          `You have already selected ${existingItem.item} item(s) and now you are trying to select ${statusData.item} more, but available stock is only ${userselete?.stock}`
        );
        return;
      }

      const updatedList = [...ListProduct];
      updatedList[existingItemIndex] = {
        ...existingItem,
        item: totalItemCount,
      };
      setListProduct(updatedList);
    } else {
      if (userselete?.stock < statusData.item) {
        toast?.error(`Only available stock is ${userselete?.stock}`);
        return;
      }

      const updatedList = [
        ...ListProduct,
        { ...userselete, item: statusData.item },
      ];
      setListProduct(updatedList);
    }

    setTimeout(() => {
      setuserselete(null);
    }, 300);

    setIsModalOpen(false);
    setstatusData({ item: "" });
  };
  const removebyindex = (e) => {
    const stdata = [...ListProduct];
    stdata.splice(e, 1);
    setListProduct(stdata);
  };
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <div style={{ width: "100px" }}>
              <img
                style={{ width: "100%" }}
                src={URL?.API_BASE_URL + item?.productcolorId?.featureImage}
                alt=""
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productId?.title}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "After Discount Price",
      dataIndex: "afterdiscountprice",
      key: "afterdiscountprice",
    },
    {
      title: "itme count",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Total Price",
      dataIndex: "totalprice",
      key: "totalprice",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.afterdiscountprice * item?.item}
            </span>
          </>
        );
      },
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              {/* <Link
                className="cursor-pointer"
                to={`/seller/listing/update-listing/${item?.pro_sulg}`}
                // onClick={() =>
                //   // navigate(
                //   //   `/seller/listing/update-listing/${item?.pro_sulg}`
                //   // )

                //   (window.location.href = `/seller/listing/update-listing/${item?.pro_sulg}`)
                // }
              >
                <Image
                  src={SvgIcon?.Pencil}
                  alt="Left Arrow"
                  width={20}
                  height={20}
                  style={{ verticalAlign: "middle" }}
                />
                
              </Link> */}
            </Tooltip>
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}
            {/* <Tooltip title="Add Point">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Add Point
              </button>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  removebyindex(index);
                }}
              >
                <Image
                  src={SvgIcon?.deletebtn}
                  alt="Left Arrow"
                  width={20}
                  height={20}
                  style={{ verticalAlign: "middle" }}
                />
                {/* <SvgIcon.deletebtn className="scale-icon edit-icon" /> */}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const hendleToprocessOrder = async () => {
    const finaldata = ListProduct.map((item) => {
      return {
        product_id: item?.productId?._id,
        color: item?.productcolorId?._id,
        size: item?._id,
        item: item?.item,
      };
    });

    const response = await dispatch(
      allapiAction.createOrderByFr({
        _id: finaldata,
      })
    );
    console.log(response);

    if (response?.success) {
      setListProduct([]);
      // setstatusDataBox1(false);
      // navigate("/account-sales-man/orders");
      // setfirst(response?.data);
      // setproductName("");
      // setsaledata({ amount: 0, commission: 0 });
    }
  };
  return (
    <div>
      <Modal
        title="Select Product"
        open={isModalOpen}
        onOk={hendleToSubmetUpdate}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={24}>Select Product</Col>
          <Col span={24}>
            <AsyncPaginate
              value={userselete}
              className="liceninputv2"
              // placeholder="location"
              placeholder="Product"
              loadOptions={loadOptionsPalyer}
              onChange={setuserselete}
              additional={{
                page: 1,
              }}
            />
          </Col>
          <Col span={24}>Itme Count</Col>
          <Col span={24}>
            <Input
              name="item"
              placeholder="enter itme count"
              value={statusData?.item}
              onChange={(e) => {
                hendleTochangedata(e);
              }}
            />
          </Col>
        </Row>
      </Modal>

      <div class="card mb-2">
        <div class="card-body">
          <div class="row flex-between align-items-center g-2 mb-3">
            <div class="col-sm-12">
              <button
                class="btn btn-primary"
                onClick={() => setIsModalOpen(true)}
              >
                Add Product
              </button>
            </div>
            {/* <div class="col-sm-6">1</div> */}
          </div>
          <div class="row flex-between align-items-center g-2 mb-3">
            <div class="col-sm-12">
              <Table
                className={"table antd_table"}
                dataSource={ListProduct}
                columns={columns}
                pagination={{
                  current: 1,
                  pageSize: 500000,
                  // onChange(current, pageSize) {
                  //     hendlePahegi(current);
                  // },
                  hideOnSinglePage: true,
                  total: 500000,
                }}
              />
            </div>
            {ListProduct?.length > 0 && (
              <button
                className="btn btn-primary ml-10"
                onClick={() => {
                  hendleToprocessOrder();
                }}
              >
                Order Complete
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frorders;

// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import { useRouter } from "next/router";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LatestListing from "./LatestListing";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate, useParams } from "react-router-dom";
import SellerHeader from "../Layout/SellerHeader";
import TruncateText from "../TruncateText/TruncateText";
import HomeSlider from "../Home/HomeSlider";
import { Button, Col, Input, Modal, Row } from "antd";
import { toast } from "react-toastify";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  PinterestShareButton,
  PinterestIcon,
  PocketShareButton,
  PocketIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
} from "react-share";
import RecentProduct from "./RecentProduct";
import Seo from "../Seo/Seo";
import InfiniteScroll from "react-infinite-scroll-component";
import CoustomImg from "../CoustomImg/CoustomImg";

function SellerStore({}) {
  const router = useNavigate();
  // const { id } = router.query;
  const { id } = useParams();
  const dispatch = useDispatch();
  const get_like_list = useSelector((state) =>
    state.allapi.get_like_list ? state.allapi.get_like_list : {}
  );
  const vendorDetails = useSelector((state) =>
    state.allapi.get_seller_details ? state.allapi.get_seller_details : {}
  );

  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [valueuser, setvalueuser] = useState({});
  const [postList, setpostList] = useState({});
  console.log(postList);
  console.log(postList?.listing);
  const prolist = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res2 = await fetch(
      URL.API_BASE_URL + `${URL.getvendorprofileproduct}`,
      requestOptions
    );

    const events2 = await res2.json();

    const postData2 = events2?.data;
    console.log(postData2);
    setpostList(postData2);
  };

  useEffect(() => {
    prolist();
    dispatch(allapiAction.getsliderList({ page: "home" }));
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));
      const userDeatail = localStorage.getItem("access_user");

      if (userDeatail) {
        const obj = JSON.parse(userDeatail);
        setvalueuser(obj);
      }
    } else {
      setValue("");
      setValuevendor("");
    }
  }, []);
  useEffect(() => {
    if (id) {
      if (value || valuevendor) {
        dispatch(allapiAction.userlikeget({ user_id: id }));
      }

      return () => {};
    }
  }, [id, valuevendor, value]);

  const hendletofollow = async () => {
    await dispatch(allapiAction.userfollownow({ user_id: id }));
    dispatch(allapiAction.getsellerprofile({ id: id }));
    return () => {};
  };

  const hendletoLike = async () => {
    await dispatch(allapiAction.userlikenow({ user_id: id }));
    dispatch(allapiAction.getsellerprofile({ id: id }));
    return () => {};
  };

  const [windowWidth, setWindowWidth] = useState(600);

  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [enquiryData, setenquiryData] = useState({});

  const onChangeData = (e) => {
    setenquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  };
  const hendletoSubmitEnquiry = async () => {
    if (!enquiryData?.name) {
      toast?.error("please enter your name");
      return;
    }
    if (!enquiryData?.number) {
      toast?.error("please enter your number");
      return;
    }
    if (!enquiryData?.address) {
      toast?.error("please enter your address");
      return;
    }

    const response = await dispatch(
      allapiAction.emailenquirysubmit({
        sellerId: vendorDetails?._id,
        sellerName: vendorDetails?.displayName,
        // productId: get_product_details?._id,
        // productName: get_product_details?.title,
        userName: enquiryData?.name,
        address: enquiryData?.address,
        // country,
        // countryCode,
        whatsappNo: enquiryData?.number,
        customerEmail: enquiryData?.email,
        message: enquiryData?.message,
        subject: "Inquiry From shop",
        // sellerEmail,
        // query,
      })
    );

    console.log(response);
    if (response?.success) {
      setstatusDataBoxReturn(false);
      setenquiryData({
        name: "",
        email: "",
        number: "",
        address: "",
        message: "",
      });
    }
  };

  const [shareshow, setstshareshow] = useState(false);
  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      // Outputs: '/blog/react-get-current-url/'

      pageUrl = window.location.href;
    }
  }

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Copied!");

    setstshareshow(false);
  }

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    // Load the initial data
    fetchProducts();
  }, []);
  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const fetchProducts = async () => {
    try {
      await delay(500);
      const response = await fetch(
        `${URL.API_BASE_URL}/publicApi/gethomepageOnProduct?page=${page}&userid=${id}`
      );
      const newProducts2 = await response.json();
      const newProducts = newProducts2?.data?.docs || [];
      // If no products are returned, set hasMore to false
      if (newProducts.length === 0) {
        setHasMore(false);
      }

      // Update products and increment page number
      setProducts((prevProducts) => [...prevProducts, ...newProducts]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  useEffect(() => {
    dispatch(allapiAction.getsellerprofile({ id: id }));
    return () => {};
  }, []);
  return (
    <div>
      <Seo
        settitle={vendorDetails?.fullName}
        titleSeo={vendorDetails?.fullName}
        seoKeyword={vendorDetails?.fullName}
        seoconcal={"https://orafame.com/vendor-store/" + vendorDetails?._id}
        seo_descriptions={vendorDetails?.fullName}
        title={vendorDetails?.fullName}
      />
      {/* <SellerHeader /> */}
      <div className="hometest">
        <HomeSlider />
      </div>

      <Modal
        title="Enquiry Now !!"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendletoSubmitEnquiry(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Store Name</Col>
            <Col span={24}>
              <Input
                name="storename"
                placeholder="enter store name..."
                value={vendorDetails?.displayName}
                // onChange={(e) => {
                //   onChangeData(e);
                // }}
              />
            </Col>
            <Col span={24}>Name</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter name..."
                value={enquiryData?.name}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Number</Col>
            <Col span={24}>
              <Input
                name="number"
                placeholder="enter number..."
                value={enquiryData?.number}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Email</Col>
            <Col span={24}>
              <Input
                name="email"
                placeholder="enter email..."
                value={enquiryData?.email}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Address</Col>
            <Col span={24}>
              <Input
                name="address"
                placeholder="enter address..."
                value={enquiryData?.address}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>message</Col>
            <Col span={24}>
              <Input
                name="message"
                placeholder="enter message..."
                value={enquiryData?.message}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Share"
        centered
        open={shareshow}
        onOk={() => setstshareshow(false)}
        onCancel={() => setstshareshow(false)}
        width={500}
        footer={null}
      >
        <div>
          <div className="row unishareIcon">
            <div className="col-2 mt-3">
              <FacebookShareButton
                url={pageUrl}
                // quote={blog?.heading}
              >
                <FacebookIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </FacebookShareButton>
            </div>
            <div className="col-2 mt-3">
              <TwitterShareButton url={pageUrl}>
                <TwitterIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TwitterShareButton>
            </div>
            <div className="col-2 mt-3">
              <WhatsappShareButton url={pageUrl}>
                <WhatsappIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </WhatsappShareButton>
            </div>
            <div className="col-2 mt-3">
              <LinkedinShareButton url={pageUrl}>
                <LinkedinIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </LinkedinShareButton>
            </div>
            <div className="col-2 mt-3">
              <EmailShareButton url={pageUrl}>
                <EmailIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </EmailShareButton>
            </div>
            <div className="col-2 mt-3">
              <MailruShareButton url={pageUrl}>
                <MailruIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </MailruShareButton>
            </div>
            <div className="col-2 mt-3">
              <OKShareButton url={pageUrl}>
                <OKIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </OKShareButton>
            </div>
            <div className="col-2 mt-3">
              <PinterestShareButton url={pageUrl}>
                <PinterestIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </PinterestShareButton>
            </div>
            <div className="col-2 mt-3">
              <PocketShareButton url={pageUrl}>
                <PocketIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </PocketShareButton>
            </div>
            <div className="col-2 mt-3">
              <RedditShareButton url={pageUrl}>
                <RedditIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </RedditShareButton>
            </div>
            <div className="col-2 mt-3">
              <TelegramShareButton url={pageUrl}>
                <TelegramIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TelegramShareButton>
            </div>
            <div className="col-2 mt-3">
              <TumblrShareButton url={pageUrl}>
                <TumblrIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TumblrShareButton>
            </div>
          </div>

          <div className="shareLinkInModel">
            {" "}
            <p>
              <b>{pageUrl}</b>
            </p>
          </div>
          <div className="shareLinkInModel">
            <Button
              variant="primary"
              onClick={() => {
                copy();
                // onCloseModal();
              }}
            >
              {!copied ? "Copy link" : "Copied!"}
            </Button>
          </div>
        </div>
      </Modal>
      <div className="marquee">
        <marquee behavior="scroll" direction="left" scrollamount="05">
          {vendorDetails?.displayName
            ? vendorDetails?.displayName
            : vendorDetails?.fullName}
        </marquee>
      </div>
      <div className="container-fluid vdstore">
        <div class="row pt-10 pb-10 mb-20 bg-light">
          <div class="col-md-4 col-12">
            <img
              src={
                vendorDetails?.sellerProfile
                  ? URL.API_BASE_URL + vendorDetails?.sellerProfile
                  : "/img/logo.png"
              }
              alt=""
              class="vendershopimage"
            />
          </div>
          <div class="col-md-8 col-12">
            <h4>
              {" "}
              {vendorDetails?.displayName
                ? vendorDetails?.displayName
                : vendorDetails?.fullName}
            </h4>
            <h5> Category : {vendorDetails?.category}</h5>
            <h6>
              {vendorDetails?.city}, {vendorDetails?.state},{" "}
              {vendorDetails?.country},{vendorDetails?.zip}
            </h6>
            {/* <h5>1234567890</h5> */}
            <div className="ps-block__footer pb-3">
              {valuevendor || value ? (
                valueuser?._id !== id ? (
                  get_like_list?.userFollow?.status == "active" ? (
                    <button
                      className="ps-btn2  "
                      onClick={() => {
                        hendletofollow();
                      }}
                      // href={"#"}
                    >
                      {vendorDetails?.follower} Following
                    </button>
                  ) : (
                    <button
                      className="ps-btn2  "
                      onClick={() => {
                        hendletofollow();
                      }}
                      // href={"#"}
                    >
                      {vendorDetails?.follower} Follow
                    </button>
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {valuevendor || value ? (
                valueuser?._id !== id ? (
                  get_like_list?.userlike?.status == "active" ? (
                    <button
                      className="ps-btn2 ml-10 "
                      onClick={() => {
                        hendletoLike();
                      }}
                      // href={"#"}
                    >
                      {vendorDetails?.like} <i className="fa fa-thumbs-up"></i>
                    </button>
                  ) : (
                    <button
                      className="ps-btn2  ml-10"
                      onClick={() => {
                        hendletoLike();
                      }}
                      // href={"#"}
                    >
                      {vendorDetails?.like} <i class="far fa-thumbs-up"></i>
                    </button>
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="ps-product__shopping">
              {/* <button
                className="ps-btn5"
                // href="#"
                onClick={() => {
                  setstatusDataBoxReturn(true);
                }}
              >
                Contect Seller
              </button> */}
              <button
                className="ps-btn3 ml-5"
                // href="#"
                onClick={() => {
                  setstshareshow(true);
                }}
              >
                Share
              </button>
              {/* <button
                className="ps-btn4 ml-5"
                // href="#"
                onClick={() => {
                  setstatusDataBoxReturn(true);
                }}
              >
                Enquiry
              </button> */}
            </div>
          </div>
          {/* <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-primary">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-success">
              <i class="fa fa-whatsapp" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-warning">
              <i class="fa fa-share" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-danger">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </button>
          </div> */}
        </div>

        <div class="ps-product-list ps-clothings  ">
          <div class="ps-section__header my-5 border border-primary">
            <h3>Product</h3>
          </div>
        </div>
        <div className="ps-section__content mt-10">
          <InfiniteScroll
            dataLength={products.length} // Length of data loaded so far
            next={fetchProducts} // Function to load more data
            hasMore={hasMore} // Controls when to stop loading more
            loader={
              <div>
                <div className="spinnerContainer">
                  {/* <div class="spinner">
                            <span>L</span>
                            <span>O</span>
                            <span>A</span>
                            <span>D</span>
                            <span>I</span>
                            <span>N</span>
                            <span>G</span>
                          </div> */}
                  <section class="dots-container">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </section>
                </div>
              </div>
            } // Loading message/component
            endMessage={<p> </p>}
          >
            <div className="container-fluid">
              <div className="row">
                {products.map((data, index) => (
                  <div
                    className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 mb-2"
                    key={index}
                  >
                    <div className="recentlostfound">
                      <div className="ps-product">
                        <div className="ps-product__thumbnail  ps-product__thumbnailms2">
                          <Link
                            to={
                              data?.typeof == "Listing"
                                ? "/listing/" + data?.pro_sulg
                                : "/product/" + data?.pro_sulg
                            }
                          >
                            <CoustomImg
                              url={URL.API_BASE_URL + data?.featureImage}
                              altkey={data?.title}
                              w1={200}
                              w2={250}
                              h1={160}
                              h2={220}
                            />
                          </Link>
                        </div>
                        <div className="ps-product__container">
                          <div className="ps-product__content">
                            <Link
                              className="ps-product__title"
                              to={
                                data?.typeof == "Listing"
                                  ? "/listing/" + data?.pro_sulg
                                  : "/product/" + data?.pro_sulg
                              }
                            >
                              <TruncateText
                                text={data?.title}
                                maxLength={windowWidth > 600 ? 25 : 15}
                              />
                              {/* {data?.title} */}
                            </Link>

                            {data?.afterdiscountprice && (
                              <p className="ps-product__price sale">
                                ₹{data?.afterdiscountprice}{" "}
                                <del>₹{data?.price} </del>
                              </p>
                            )}
                            <Link
                              className="ps-product__vendor"
                              to={"/vendor-store/" + data?.sellerId?._id}
                            >
                              {data?.sellerId?.displayName}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

export default SellerStore;

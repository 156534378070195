import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { LogoutOutlined } from "@ant-design/icons";
// import { useRouter } from "next/router";
import React from "react";
import { DeleteToken } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const TopBar = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    DeleteToken();
    // navigate("/login", { replace: true });
    navigate("/login");
  };
  const get_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  console.log(get_profile);

  return (
    <div className="flex justify-content-between">
      <div>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          }
        )}
      </div>
      <div>
        <p className="fw-600" style={{ color: "#FDA509", marginTop: "15px",fontSize:"17px" }}>
          {get_profile?.isaraeDistibutor ? "Pincode Distributor" :get_profile?.isdistrictDistibutor ? "District Distributor":get_profile?.isstateDistibutor ? "State Distributor": ""}
        </p>
      </div>
      <div className="mr-20">
        <span className="logout-btn" onClick={() => handleLogout()}>
          Logout
          <LogoutOutlined />
        </span>
      </div>
    </div>
  );
};

export default TopBar;
